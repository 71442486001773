.icono{
    min-width: 6vw;
    min-height: 6vh;
    margin-left: 1vw;
}

.btn-cargar{
    background-color: #0c299b !important;
}

.actions{
    margin-left: 5vw;
}

.info{
    display: inline-block;
    vertical-align: top;
    margin-left: 4vw;
    min-width: 60%;
}

.status{
    display: inline-block;
    vertical-align: top;
    margin-left: 4vw;
    min-width: 30%;
}

.icon-response{
    min-width: 15vw;
    min-height: 15vh;
}

.icon-error{
    color: red;
}

.icon-success{
    color: green;
}