.header {
    margin: 0px;
    padding: 2vw;
    width: 100%;
}

.table-container {
    padding: 1px 1px 1px 1px;
    text-align: center;
    min-width: 100%;
}

.cargando {
    margin-top: 20vh;
}

.home-conatiner {
    text-align: right;
}

.home-icon {
    display: inline;
    vertical-align: top;
    margin-left: 2vw;
    color: #6c757d;
}

.home-title {
    display: inline;
    vertical-align: top;
    color: #6c757d;
}

.mensaje {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}