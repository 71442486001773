.header-container{
    width: 100%;
}

.titulo{
    display: inline-block;
    min-width: 80vw;
    margin-right: 2vw;
}

.cargar{
    display: inline-block;
    margin-right: 1px;
}

